import { ApprovalModalForm } from "./ApprovalModalForm";

const ApprovalModalFormWrapper = ({
image,
setImage,
setOpenApprovalModal,
fetchImageApprovals
}) => {
  const itemIdForUpdate = null;
  const isLoading = false;

  if (image) {
    return (
      <ApprovalModalForm
        image={image}
        setImage={setImage}
        setOpenApprovalModal={setOpenApprovalModal}
        fetchImageApprovals={fetchImageApprovals}
      />
    );
  }
  return null;
};

export { ApprovalModalFormWrapper };
