import { useState } from "react";
import { UsersListLoading } from "../../Reviews/Review/users-list/components/loading/UsersListLoading";
import { KTCardBody, KTIcon } from "../../../../_metronic/helpers";
import dayjs from "dayjs";
import { Pagination } from "../../../../components/Pagination";
import { Link } from "react-router-dom";
import ImageViewer from "awesome-image-viewer";
import { ApprovalModal } from "../ApprovalModal/ApprovalModal";

const headers = [
  { title: 'Listing Name', className: 'w-200' },
  { title: 'Date', className: 'w-200' },
  { title: 'View Image', className: 'w-200' },
  { title: 'Uploaded By', className: 'w-150' },
  { title: 'Requested By', className: 'w-150' },
  { title: 'Action', className: 'w-200' },
]

export const ImageTable = ({
  setImages,
  images,
  isLoading,
  setLoading,
  fetchImageApprovals,
  total,
  page,
  setPage,
  pageSize,
  setPageSize,
  haveAccess,
  currentUser,
}) => {
  const [openApprovalModal, setOpenApprovalModal] = useState(false)
  const [image, setImage] = useState(null)

  return (
    <KTCardBody className='py-4'>
      <h6 style={{ color: '#7e8299', textAlign: 'right' }}>Total: {total}</h6>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map(column => (
                <th
                  key={column.title}
                  className={column.className}
                  style={{ textAlign: column.title === 'Action' ? 'center' : 'left' }}
                >
                  {column.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold'>
            {images.length > 0 && (
              images.map((row, i) => (
                <tr key={row.asset_id}>
                  <td className='w-200' style={{ minWidth: '240px' }}><Link to={`/listings/${row.asset_owner}`}>{row.owner_name || '-'}</Link></td>
                  <td className='w-200' style={{ minWidth: '160px' }}>{dayjs(row.updatedAt).format('MMMM DD YYYY')}</td>
                  <td className='w-200 pointer' style={{ minWidth: '120px' }}
                    onClick={() =>
                      new ImageViewer({
                        images: [
                          {
                            mainUrl: process.env.REACT_APP_IMAGE_URL + "1920:720:" + (row?.pending_file || row.latest_file)?.slice(1)
                          }
                        ],
                        isZoomable: false,
                        closeOnEsc: true
                      })
                    }
                  >
                    <img src={process.env.REACT_APP_IMAGE_URL + '300:200:' + (row?.pending_file || row.latest_file)?.slice(1)} width={'120px'} height={'80px'} />
                  </td>
                  <td className='w-200' style={{ minWidth: '150px' }}>{row.uploader_name}</td>
                  <td className='w-200' style={{ minWidth: '150px' }}>{row.requester_name || '-'}</td>
                  <td className='w-200' style={{ justifyContent: 'center', display: 'grid', gap: '5px' }}>
                    <div>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={row.asset_id}
                        checked={row.make_profile}
                        onChange={() => {
                          setImages(prevState => prevState.map(image => image.asset_id === row.asset_id ? { ...image, make_profile: !image.make_profile } : image))
                        }}
                      />
                      <label
                        className="form-check-label mx-2 text-dark"
                        htmlFor={row.asset_id}
                      >
                      Profile Photo
                      </label>
                    </div>
                    <div className="d-flex gap-2">
                      <button className="d-flex justify-content-center align-items-center btn-sm rounded bg-success w-100"
                        disabled={!haveAccess(currentUser, 'listing_photo_approve')}
                        onClick={() => {
                          setImage({
                            ...row,
                            type: 'accept'
                          })
                          setOpenApprovalModal(true)
                        }}
                      ><KTIcon iconName="check" className="fs-1 text-white" /></button>
                      <button className="d-flex justify-content-center align-items-center btn-sm rounded bg-danger w-100"
                        disabled={!haveAccess(currentUser, 'listing_photo_stash')}
                        onClick={() => {
                          setImage({
                            ...row,
                            type: 'reject'
                          })
                          setOpenApprovalModal(true)
                        }}
                      ><KTIcon iconName="cross" className="fs-1 text-white" /></button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        {images && !images.length > 0 &&
          <div className='d-flex text-center text-gray-600 fw-bolder fs-6 w-100 align-content-center justify-content-center mt-10'>
            No matching records found
          </div>
        }
      </div>
      <Pagination
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        total={total}
      />
      {isLoading && <UsersListLoading />}
      {
        openApprovalModal &&
        <ApprovalModal
          image={image}
          setImage={setImage}
          fetchImageApprovals={fetchImageApprovals}
          openApprovalModal={openApprovalModal}
          setOpenApprovalModal={setOpenApprovalModal}
        />
      }
    </KTCardBody>
  );
};
