import { useState } from 'react';

const Slider = ({ fees, setFees, defaultFees }) => {
  const allowedValues = [0, defaultFees, 100];
  const handleChange = (e) => {
    const value = Number(e.target.value);
    const closestValue = allowedValues.reduce((prev, current) =>
      Math.abs(current - value) < Math.abs(prev - value) ? current : prev
    );
    setFees(closestValue);
  };

  return (
    <div className="fv-row mb-10">
        <div className="d-flex justify-content-between items-center mb-2">
          <h5>Platform Fee</h5>
          <h5>{fees}%</h5>
        </div>
        <div className="position-relative">
          <input
            type="range"
            min="0"
            max="100"
            step="1"
            value={fees}
            onChange={handleChange}
            className="w-100 rounded cursor-pointer"
            list="fee-markers"
            style={{
              background: `linear-gradient(to right, #ffa459 ${fees}%, #e5eaee ${fees}%)`,
            }}
          />
          <datalist id="fee-markers">
            <option value="0">0%</option>
            <option value={defaultFees}>{defaultFees}%</option>
            <option value="100">100%</option>
          </datalist>
          <div className="d-flex justify-content-between items-center postion-relative">
          <span className='position-absolute' style={{left: '0%' }}>0%</span>
         {defaultFees > 0 && <span className='position-absolute' style={{left: `${defaultFees}%`, transform: `translateX(-${defaultFees}%)` }}>{defaultFees}%</span>}
          <span className='position-absolute left-100' style={{left: '100%', transform: 'translateX(-100%)' }}>100%</span>
        </div>
        </div>
    </div>
  );
};

export default Slider;
