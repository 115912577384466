import React, { useState, useCallback, useEffect } from "react";
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import SortableImage from "./SortableImage";
import ImageCard from "./ImageCard";
import axios from "axios";
import { toast } from "react-toastify";


const SortWrapper = ({ images, setImages, listing, showImageStatus, isLoading, setLoading, setReasonModal, getListing, getAllImages, setOpenPhotoEditModal }) => {
  const [activeId, setActiveId] = useState(null);

  const sensors = useSensors(useSensor(MouseSensor, {
    activationConstraint: {
      distance: 5,
    },
  }), useSensor(TouchSensor, {
    activationConstraint: {
      distance: 5,
    }
  }
  ));

  const handleDragStart = useCallback((event) => {
    setActiveId(event.active.id);
  }, []);


  const handleDragEnd = useCallback((event) => {
    const { active, over } = event;

    if (over?.id && active.id !== over?.id) {
      setImages((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        const updatedImages = arrayMove(items, oldIndex, newIndex);
        axios.put(`${process.env.REACT_APP_API_URL}/listing/${listing?.listing_id}`, {
          image_order: updatedImages.map((image) => +image.id),
        })
          .then((res) => {
            getListing();
          })
          .catch((err) => {
            setLoading(false);
            getAllImages();
            toast.error("Error Changing Order");
          });
  
        return updatedImages;
      });
    }

    setActiveId(null);
  }, []);

  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={images} strategy={rectSortingStrategy}>
        <div className="d-flex flex-wrap gap-4 justify-content-start">
          {images.map((id) => (
            <SortableImage key={id?.id} id={id?.id} data={id} listing={listing} showImageStatus={showImageStatus} isLoading={isLoading} setLoading={setLoading} setReasonModal={setReasonModal} getAllImages={getAllImages} getListing={getListing} setOpenPhotoEditModal={setOpenPhotoEditModal} />
          ))}
        </div>
      </SortableContext>
      <DragOverlay adjustScale style={{ transformOrigin: "0 0" }}>
        {activeId ? <ImageCard id={activeId} data={images?.find((i) => i.id === activeId)} listing={listing} showImageStatus={showImageStatus} isLoading={isLoading} setLoading={setLoading} setReasonModal={setReasonModal} getAllImages={getAllImages} getListing={getListing} isDragging setOpenPhotoEditModal={setOpenPhotoEditModal} /> : null}
      </DragOverlay>
    </DndContext>
  );
};

export default SortWrapper;

