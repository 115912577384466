import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { listingLogs } from "../../../../../_metronic/helpers/utils";
import { convertUTCTimeToListingTime } from "../../../../../_metronic/helpers/convertTime";

const UserEditModalForm = ({
  setSelectedListingId,
  listing,
  setOpenTimelineModal,
  amenities
}) => {
  const navigate = useNavigate();
  const [admins, setAdmins] = useState([]);
  const [timeline, setTimeline] = useState([...listing.timeline.sort((a, b) => b.id - a.id)]);


  const cancel = () => {
    setSelectedListingId();
    setOpenTimelineModal(false);
  };


  const adminList = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/admin/public`).then((res) => {
      setAdmins(res.data);
    });
  }


  useEffect(() => {
    adminList()
  }, [])


  const extractTitlesFromIds = (note) => {
    const amenityIds = note.match(/\d+/g);

    if (amenityIds) {
      return amenityIds.map(id => {
        const amenity = amenities.find(item => item.id === Number(id));
        return amenity ? amenity.title : `Unknown ID: ${id}`;
      });
    }
    return [];
  };




  return (
    <>
      <div className="kt_body">
        <div style={{ maxHeight: '300px', overflowY: 'auto', minHeight: 'auto' }}>
          {
            listing.timeline && listing.timeline.length > 0 ?
              <>
                {timeline.map((item, index) => {
                  return <div
                    key={index}
                    style={{ display: 'flex', gap: '5px', background: '#f9f9f9', marginBottom: '10px', padding: '10px', borderRadius: '10px', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <h4 style={{ marginBottom: '2px' }}> {listingLogs(item.action_id)}</h4>
                      <h6>{convertUTCTimeToListingTime(item.createdAt, listing.timezone_id, 0, "dddd, Do MMMM YYYY, hh:mm A")}</h6>
                    </div>
                    <h6 style={{ marginBottom: '2px' }}>Action Done By: {item.action_by_role == 2 ? `${listing.owner_first_name} ${listing.owner_last_name} (Owner)` : item.action_by_role == 3 ? `${admins?.find((_i) => _i.id == item.action_by)?.name || ""} (Admin)` : 'Auto'}</h6>
                    {item.action_id === 10 && item.notes ? (
                      <div>
                        <h6>{item?.notes?.includes("Amenities added") ? "New Amenities Added" : "Amenities Removed"}</h6>
                        <ul>
                          {extractTitlesFromIds(item.notes)?.map((title, index) => (
                            <li key={index}>{title}</li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      item.action_id !== 10 && item.action_id !== 16 && item.action_id !== 17 && item.notes && (
                        <h6>{item.notes}</h6>
                      )
                    )}

                  </div>
                })
                }
              </>
              :
              <h4>No Timeline yet for this listing</h4>
          }
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <button
            type="button"
            className="btn mt-4 w-100 btn-primary"
            onClick={() => cancel()}
            style={{
              color: "#fff",
              height: "46px",
              fontWeight: "700",
            }}
          >
            Go Back
          </button>
        </div>
      </div>
    </>
  );
};

export { UserEditModalForm };
