import { useEffect, useState} from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuth } from "../../../auth";
import { PhotoEditModal } from "../PhotoEditModal/PhotoEditModal";
import PhotoUploader from "./PhotoUploader";
import "./styles.scss";
import SortWrapper from "./SortWrapper"
import { useCanvas } from "../../../ImageEditor/context/canvasContext";
const PhotoUploaderWrapper = ({
  listing,
  getListing,
  openPhotoUploaderModal
}) => {
  const { currentUser, haveAccess } = useAuth();
  const [pageSize, setPageSize] = useState(50);
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState('0,1,2,3')
  const [total, setTotal] = useState(1);
  const [images, setImages] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [reasonModal, setReasonModal] = useState(true)
  const [openPhotoEditModal, setOpenPhotoEditModal] = useState(false)

  const getAllImages = () => {
    setLoading(true)
    axios.get(`${process.env.REACT_APP_API_V3_URL}/listing/images?listing_id=${listing.listing_id}&page=${page}&page_size=${pageSize}&status=${status}`)
      .then(res => {
        let listingImages = res.data.data
        const order = listing.image_order
        if(order){
          const orderedImages = listingImages.filter((image) => order.includes(image.asset_id)).sort((a, b) => {
            return order.indexOf(a.asset_id) - order.indexOf(b.asset_id);
        })
          const missingImages = listingImages.filter((image) => !order.includes(image.asset_id)).sort((a, b) => a.asset_id - b.asset_id)
          listingImages = [...orderedImages, ...missingImages]
        }
        setImages(listingImages.map(item => ({ ...item, id: `${item.asset_id}` })))
        setTotal(res.data.total)
        setLoading(false)
      }).catch(err => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast.error(err.response.data.message)
          setLoading(false)
        }
      })
  }

  useEffect(() => {
    if(listing){
      getAllImages()
    }
  }, [status, listing])


  const showImageStatus = (status) => {
    switch (status) {
      case 0:
        return {
          text: 'Pending',
          color: 'warning'
        }
      case 1:
        return {
          text: 'Approved',
          color: 'success'
        }
      case 2:
        return {
          text: 'Rejected',
          color: 'danger'
        }
      case 3:
        return {
          text: 'Pending Approval',
          color: 'info'
        }
      case -1:
        return {
          text: 'Deleted',
          color: 'danger'
        }
      default:
        return {
          text: 'Pending',
          color: 'warning'
        }
    }
  }

  useEffect(() => {
    if (reasonModal?.status) {
      document.body.classList.add("modal-open");

      return () => {
        document.body.classList.remove("modal-open");
      };
    }
  }, [reasonModal]);

  return (
    <>
      <div className={`kt_body w-100 ${openPhotoUploaderModal ? 'uploader-wrapper' : 'hide-uploader-wrapper'}`}>
        {(haveAccess(currentUser, 'listing_photo_upload')) && <PhotoUploader getAllImages={getAllImages} listing={listing} />}
        {(haveAccess(currentUser, 'listing_photo_list')) && <div>
          <div className="d-flex justify-content-between align-items-center my-5" style={{ width: '90%' }}>
            <h2>Uploaded Images</h2>
            <select
              className="form-select form-select-solid fw-bolder"
              data-placeholder="Select Filter"
              data-hide-search="true"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              style={{ width: 'fit-content' }}
            >
              <option value='0,1,2,3' selected>All Images</option>
              <option value='0'>Pending Images</option>
              <option value='1'>Approved Images</option>
              <option value='3'>Pending For Approval Images</option>
              <option value='2'>Rejected Images</option>
            </select>
          </div>
          {images && images?.length ?
          <SortWrapper images={images} setImages={setImages} listing={listing} showImageStatus={showImageStatus} isLoading={isLoading} setLoading={setLoading} setReasonModal={setReasonModal} getAllImages={getAllImages} getListing={getListing}
            setOpenPhotoEditModal={setOpenPhotoEditModal}
          />
          :
          <h3 className="text-danger">No {showImageStatus(+status).text} Images added yet</h3>
          }
        </div>
        }
      </div>
      {reasonModal.status && (
        <div className="modal_wrapper">
          <div className="modal_container">
            <p className="mb-5 fs-5 text-danger fw-bold"><span className="text-dark">Reject Reason:</span> {reasonModal?.rejectReason}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-dark"
                style={{ width: 'max-content' }}
                onClick={() => setReasonModal({
                  status: false
                })}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    {openPhotoEditModal && <PhotoEditModal setOpenPhotoEditModal={setOpenPhotoEditModal} listing={listing} getListing={getListing} getAllImages={getAllImages} />}
    </>
  );
};

export { PhotoUploaderWrapper };
