const UsersListLoading = ({ onTop=false }) => {
  const styles = {
    borderRadius: '0.475rem',
    backgroundColor: '#fff',
    color: '#7e8299',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
    textAlign: 'center',
    position: onTop ? 'absolute' : 'relative',
    zIndex: onTop ? 9 : 1
  }

  return <div style={{ ...styles }}>Processing...</div>
}

export { UsersListLoading }
