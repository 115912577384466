import { useEffect, useState } from "react";
import "./styles.scss"
import { fabric } from 'fabric';
import { Square, Circle, Triangle, RectangleHorizontal, Octagon, MessageSquare, MoveRight, Minus, RotateCcw, RotateCw, Bold, MapPin, Truck, Type, Italic, FlipHorizontal, FlipVertical, BringToFront } from 'lucide-react';
import { CirclePicker, AlphaPicker } from 'react-color';
import { useCanvas } from "../context/canvasContext";
import axios from "axios";
import { toast } from "react-toastify";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { truckSide, truckTop, trailerSide, trailerTop } from "./vehicleIcon";
import { colors } from "../utils/index";

const fontFamilies = [
  'Arial',
  'Inter',
  "IBM Plex Sans",
  "Roboto Slab",
];

const fontSizes = [12, 14, 16, 18, 20, 24, 28, 32, 36, 42, 48, 56, 64, 72];
const shapes = ['Square', 'Circle', 'Triangle', 'Rectangle', 'Stop', 'Quote', 'Arrow', 'Line', 'Pin', 'Type', 'Logo', 'TruckSide', 'TruckTop', 'TrailerSide', 'TrailerTop'];

const Sidebar = ({
  getListing,
  getAllImages,
  setOpenPhotoEditModal,
}) => {
  const { canvasRef, canvasLoading, imagePreview, fillColor, setFillColor, mainColor, setMainColor, fontFamily, setFontFamily, fontSize, setFontSize, isBold, setIsBold, isItalic, setIsItalic, selectedShape, selectedImage, setImagePreview, setCanvasLoading, setSelectedShape } = useCanvas();
    const [isOpen, setIsOpen] = useState(false);
  const [selectedStroke, setSelectedStroke] = useState(null);
  const onShapeSelection = (shape) => {
    switch (shape) {
      case 'Square':
        return onAddSquare();
        break;
      case 'Circle':
        return onAddCircle();
        break;
      case 'Triangle':
        return onAddTriangle();
        break;
      case 'Rectangle':
        return onAddRectangle();
        break;
      case 'Stop':
        return onAddStop();
        break;
      case 'Quote':
        return onAddQuote();
        break;
      case 'Arrow':
        return onAddArrow();
        break;
      case 'Line':
        return onAddLine();
        break;
      case 'Pin':
        return onAddPin();
        break;
      case 'Logo':
        return onAddLogo();
        break;
      case 'TruckTop':
        return onAddTruck('trucktop');
        break;
      case 'TruckSide':
        return onAddTruck('truckside');
        break;
      case 'TrailerTop':
        return onAddTruck('trailertop');
        break;
      case 'TrailerSide':
        return onAddTruck('trailerside');
      case 'Type':
        return onAddType();
      default:
        break;
    }
  }

  const deleteObject = (_eventData, transform) => {
    const canvas = transform.target.canvas;
    canvas.remove(transform.target);
    canvas.requestRenderAll();
  }

  const cloneObject = (_eventData, transform) => {
    const canvas = transform.target.canvas;
    transform.target.clone((cloned) => {
      cloned.left += 10;
      cloned.top += 10;
      cloned.controls.deleteControl = transform.target.controls.deleteControl;
      cloned.controls.cloneControl = transform.target.controls.cloneControl;
      canvas.add(cloned);
      canvas.bringToFront(cloned);
      canvas.requestRenderAll();
    });
  }

  const deleteIcon =
    "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E";


  const cloneIcon =
    "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCAgZmlsbD0iI2ZmZiIgZD0iTTQ2NCAwYzI2LjUgMCA0OCAyMS41IDQ4IDQ4djI4OGMwIDI2LjUtMjEuNSA0OC00OCA0OEgxNzZjLTI2LjUgMC00OC0yMS41LTQ4LTQ4VjQ4YzAtMjYuNSAyMS41LTQ4IDQ4LTQ4aDI4OE0xNzYgNDE2Yy00NC4xIDAtODAtMzUuOS04MC04MFYxMjhINDhjLTI2LjUgMC00OCAyMS41LTQ4IDQ4djI4OGMwIDI2LjUgMjEuNSA0OCA0OCA0OGgyODhjMjYuNSAwIDQ4LTIxLjUgNDgtNDh2LTQ4SDE3NnoiLz48L3N2Zz4="

  const deleteImg = document.createElement('img');
  deleteImg.src = deleteIcon;

  const cloneImg = document.createElement('img');
  cloneImg.src = cloneIcon;

  const renderIcon = (icon) => {
    return function (ctx, left, top, _styleOverride, fabricObject) {
      const size = this.cornerSize;
      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
      ctx.drawImage(icon, -size / 2, -size / 2, size, size);
      ctx.restore();
    };
  }

  const onAddSquare = () => {
    const square = new fabric.Rect({
      width: 100,
      height: 100,
      fill: mainColor,
      stroke: '#000000',
      strokeWidth: 1,
      left: 100,
      top: 100
    })

    canvasRef.current.add(square)
    canvasRef.current.renderAll()
  }
  const onAddCircle = () => {
    const circle = new fabric.Circle({
      radius: 50,
      fill: mainColor,
      stroke: '#000000',
      strokeWidth: 1,
      left: 100,
      top: 100
    });
    canvasRef.current.add(circle);
    canvasRef.current.renderAll()
  }
  const onAddTriangle = () => {
    const triangle = new fabric.Triangle({
      width: 100,
      height: 100,
      fill: mainColor,
      stroke: '#000000',
      strokeWidth: 1,
      left: 100,
      top: 100
    })

    canvasRef.current.add(triangle);
    canvasRef.current.renderAll()
  }
  const onAddRectangle = () => {
    const rect = new fabric.Rect({
      width: 150,
      height: 60,
      fill: mainColor,
      stroke: '#000000',
      strokeWidth: 1,
      left: 100,
      top: 100,
    });
    canvasRef.current.add(rect);
    canvasRef.current.renderAll()

  }
  const onAddStop = () => {
    const size = 40;
    const points = [];
    const sides = 8;

    for (let i = 0; i < sides; i++) {
      const angle = (i * Math.PI * 2) / sides - Math.PI / sides;
      points.push({
        x: size * Math.cos(angle) + size,
        y: size * Math.sin(angle) + size
      });
    }

    const stopSign = new fabric.Polygon(points, {
      left: 100,
      top: 100,
      fill: mainColor,
      stroke: '#0000000',
      strokeWidth: 2,
    });

    canvasRef.current.add(stopSign);
    canvasRef.current.renderAll();
  }
  const onAddQuote = () => {
    const path = new fabric.Path('M 10,10' +
      'Q 0,10 0,20' +
      'L 0,80' +
      'Q 0,90 10,90' +
      'L 70,90' +
      'Q 80,90 80,80' +
      'L 80,50' +
      'L 95,65' +
      'L 80,40' +
      'L 80,20' +
      'Q 80,10 70,10' +
      'Z', {
      left: 100,
      top: 100,
      fill: mainColor,
      stroke: '#000000',
      strokeWidth: 2,
    });

    canvasRef.current.add(path);
    canvasRef.current.renderAll();
  }
  const onAddArrow = () => {
    const points = [
      { x: 0, y: 20 },
      { x: 80, y: 20 },
      { x: 80, y: 0 },
      { x: 100, y: 25 },
      { x: 80, y: 50 },
      { x: 80, y: 30 },
      { x: 0, y: 30 }
    ];

    const arrow = new fabric.Polygon(points, {
      left: 100,
      top: 100,
      fill: mainColor,
      stroke: '#000000',
      strokeWidth: 1,
    });

    canvasRef.current.add(arrow);
    canvasRef.current.renderAll();
  }

  const onAddLine = () => {
    const line = new fabric.Line([100, 100, 200, 200], {
      stroke: mainColor,
      strokeWidth: 5,
      left: 100,
      top: 100
    });
    canvasRef.current.add(line);
    canvasRef.current.renderAll()
  }

  const onAddPin = () => {
    fabric.loadSVGFromString(
      `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-map-pin"><path d="M20 10c0 4.993-5.539 10.193-7.399 11.799a1 1 0 0 1-1.202 0C9.539 20.193 4 14.993 4 10a8 8 0 0 1 16 0"/><circle cx="12" cy="10" r="3"/></svg>`,
      (objects, options) => {
        const obj = fabric.util.groupSVGElements(objects, options);
        obj.getObjects().forEach((path) => {
          path.set({ fill: fillColor });
        });

        obj.set({ left: 100, top: 100 });
        canvasRef.current.add(obj);
        canvasRef.current.renderAll();
      }
    );
  }

  const onAddLogo = () => {
    const convertImageToBase64 = async () => {
      try {
        const response = await fetch(toAbsoluteUrl("/media/logos/logo.png"));
        const blob = await response.blob();
        const reader = new FileReader();
        reader.onloadend = () => {
          fabric.Image.fromURL(reader.result, (image) => {
            image.scaleToHeight(50);
            image.set({
              left: 0,
              top: 0,
              crossOrigin: "Anonymous"
            });
            canvasRef.current.add(image);
            canvasRef.current.renderAll();
          });
        };
        reader.readAsDataURL(blob);
      } catch (error) {
        console.error("Error loading image: ", error);
      }
    };
    convertImageToBase64();
  };

  const onAddTruck = (type) => {
    const iconType = {
      'trailertop': trailerTop,
      'trailerside': trailerSide,
      'trucktop': truckTop,
      'truckside': truckSide
    }
    fabric.loadSVGFromString(iconType[type], (objects, options) => {
      const obj = fabric.util.groupSVGElements(objects, options);
      obj.getObjects().forEach((path) => {
        console.log(path)
        path.set({ stroke: '#000000', strokeWidth: 5, fill: fillColor });
      });
      obj.set({ left: 100, top: 100 });
      canvasRef.current.add(obj);
      canvasRef.current.renderAll()
    })
  }

  const onAddType = () => {
    const text = new fabric.Textbox('Type Here', {
      width: 120,
      left: 100,
      top: 100,
      fill: fillColor,
      fontSize: fontSize,
      fontWeight: isBold ? 'bold' : 'normal',
      fontStyle: isItalic ? 'italic' : 'normal',
      fontFamily: fontFamily,
      editable: true,
      selectable: true,
      BringToFront: true
    })
    canvasRef.current.add(text);
    canvasRef.current.renderAll()
  }

  const onRotate = (type) => {
    if (canvasRef && canvasRef.current) {
      const canvas = canvasRef.current;
      const backgroundImage = canvas.backgroundImage;

      if (backgroundImage) {
        const fixedWidth = canvas.getWidth();
        const fixedHeight = canvas.getHeight();

        let currentAngle = backgroundImage.angle || 0;
        let newAngle;

        if (type === 'right') {
          newAngle = currentAngle + 90;
          newAngle = newAngle % 360;
        } else {
          newAngle = currentAngle - 90;
          newAngle = ((newAngle % 360) + 360) % 360;
        }

        backgroundImage.set({
          angle: newAngle,
          originX: 'center',
          originY: 'center',
          left: fixedWidth / 2,
          top: fixedHeight / 2
        });

        const angleInRadians = fabric.util.degreesToRadians(newAngle);
        const origWidth = backgroundImage.width;
        const origHeight = backgroundImage.height;
        const rotatedWidth = Math.abs(origWidth * Math.cos(angleInRadians)) +
          Math.abs(origHeight * Math.sin(angleInRadians));
        const rotatedHeight = Math.abs(origWidth * Math.sin(angleInRadians)) +
          Math.abs(origHeight * Math.cos(angleInRadians));
        const scaleX = fixedWidth / rotatedWidth;
        const scaleY = fixedHeight / rotatedHeight;
        const scale = Math.min(scaleX, scaleY);

        backgroundImage.set({
          scaleX: [0, 180, 360].includes(newAngle) ? scaleX : scale,
          scaleY: [0, 180, 360].includes(newAngle) ? scaleY : scale,
        });
        canvas.renderAll();
      }
    }
  };

  const onFlip = (type) => {
    if (canvasRef) {
      if (type === 'horizontal') {
        canvasRef.current.backgroundImage.flipX = !canvasRef.current.backgroundImage.flipX
      } else {
        canvasRef.current.backgroundImage.flipY = !canvasRef.current.backgroundImage.flipY
      }
      canvasRef.current.renderAll()
    }
  }

  const onBringToFront = () => {
    const activeObj = canvasRef.current.getActiveObject();
    if (activeObj) {
      canvasRef.current.bringToFront(activeObj).discardActiveObject(activeObj).renderAll();
    }
  }

  const onSaveImage = async () => {
    if (canvasRef) {
      const dataURL = canvasRef.current.toDataURL({
        format: 'png',
        quality: 1
      });

      const blobFile = await fetch(dataURL).then(file => file.blob());
      const json = canvasRef.current.toJSON();
      const jsonString = JSON.stringify(json);
      const blobJSON = new Blob([jsonString], { type: 'application/json' });
      const formData = new FormData();
      formData.append('asset_id', selectedImage.asset_id);
      formData.append('image', blobFile, `${new Date().getMilliseconds()}.png`);
      formData.append('layers', blobJSON, 'layers.json');
      axios.put(
        `${process.env.REACT_APP_API_V3_URL}/listing/images`,
        formData
      )
        .then(res => {
          toast.success('Image saved successfully')
          getListing()
          getAllImages()
          setImagePreview(null)
          setSelectedShape(null)
          setCanvasLoading(false)
          setOpenPhotoEditModal(false)
          canvasRef.current = null
        })
        .catch(err => {
          if (err && err.response && err.response?.data && err.response?.data?.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error("Technical Error, Please Try Again");
          }
        });
    }
  }

  useEffect(() => {
    if (selectedShape && canvasRef?.current) {
      if (selectedShape.type !== 'group' || selectedShape.type !== 'textbox') {
        canvasRef.current.getActiveObject().set({
          fill: mainColor,
        });
      }
      if (selectedShape.type === 'line') {
        canvasRef.current.getActiveObject().set({
          stroke: fillColor
        });
      }
      if (selectedShape.type === 'group') {
        const obj = canvasRef.current.getActiveObject()
        obj.getObjects().forEach((path) => {
          path.set({ fill: fillColor });
        });
      }
      if (selectedShape.type === 'textbox') {
        canvasRef.current.getActiveObject().set({
          fill: fillColor,
          fontSize: fontSize,
          fontFamily: fontFamily,
          fontWeight: isBold ? 'bold' : 'normal',
          fontStyle: isItalic ? 'italic' : 'normal'
        });
      }
      if (selectedShape) {
        canvasRef.current.getObjects().forEach(obj => {
          if (!obj.controls.deleteControl) {
            obj.controls.deleteControl = new fabric.Control({
              x: 0.5,
              y: -0.5,
              offsetY: -16,
              offsetX: 16,
              cursorStyle: 'pointer',
              mouseUpHandler: deleteObject,
              render: renderIcon(deleteImg),
              cornerSize: 18,
            });
            obj.setCoords()
          }

          if (!obj.controls.cloneControl) {
            obj.controls.cloneControl = new fabric.Control({
              x: -0.5,
              y: -0.5,
              offsetY: -16,
              offsetX: -16,
              cursorStyle: 'pointer',
              mouseUpHandler: cloneObject,
              render: renderIcon(cloneImg),
              cornerSize: 18,
            });
            obj.setCoords()
          }
        });
      }

      canvasRef.current.renderAll();
    }
  }, [selectedShape, canvasRef, fontSize, fillColor, fontFamily, isBold, isItalic, mainColor]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Delete' || event.key === 'Backspace') {
        if (canvasRef?.current && selectedShape) {
          if (selectedShape.type !== 'textbox') {
            canvasRef.current.remove(canvasRef.current.getActiveObject())
            canvasRef.current.renderAll()
          }
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedShape, canvasRef]);


  return (
    <div style={{ width: '20%', background: '#e0e0e0' }} className='d-flex flex-column py-3 px-3 rounded'>
      <div>
        <>
          <div className="d-flex justify-content-between">
            <button className="btn btn-sm btn-transparent text-danger fw-bold p-0"
              disabled={canvasLoading}
              onClick={() => {
                if (canvasRef) {
                  canvasRef.current.getObjects().forEach((obj) => {
                    canvasRef.current?.remove(obj)
                  })
                  canvasRef.current.renderAll()
                }
              }}
            >Clear All</button>
            {selectedShape && <button className="btn btn-sm btn-transparent text-danger fw-bold p-0"
              onClick={() => {
                if (canvasRef) {
                  canvasRef.current.remove(canvasRef.current.getActiveObject())
                  canvasRef.current.renderAll()
                }
              }}
            >Remove Shape</button>}
          </div>
          <div className='separator my-1' style={{ border: '1px solid #ccc' }}></div>
        </>
      </div>
      <h3 className='text-center fw-bold mb-2'>Shapes</h3>
      <div className="d-flex flex-col flex-wrap gap-2">
        {shapes.map((shape) => {
          const ShapeIcon = {
            Square: Square,
            Circle: Circle,
            Triangle: Triangle,
            Rectangle: RectangleHorizontal,
            Stop: Octagon,
            Quote: MessageSquare,
            Arrow: MoveRight,
            Line: Minus,
            Pin: MapPin,
            Type: Type
          }[shape];
          return (
            <button
              key={shape}
              disabled={canvasLoading}
              onClick={() => onShapeSelection(shape)}
              className={`p-2 rounded shape-btn text-black bg-hover-light m-0`}
            >
              {
                (shape === 'Logo') ?
                  <img src={toAbsoluteUrl("/media/logos/logo.png")} alt='truck-logo' width={24} height={24} />
                  :
                  (shape === 'TruckSide') ?
                    <img src={toAbsoluteUrl("/media/logos/truckside.png")} alt='truck-side-icon' width={24} height={24} style={{ transform: 'scale(1.2)', objectFit: 'contain' }} />
                    :
                    (shape === 'TruckTop') ?
                      <img src={toAbsoluteUrl("/media/logos/trucktop.png")} alt='truck-top-icon' width={24} height={24} style={{ transform: 'scale(1.2)', objectFit: 'contain' }} />
                      :
                      (shape === 'TrailerSide') ?
                        <img src={toAbsoluteUrl("/media/logos/trailerside.png")} alt='trailer-side-icon' width={24} height={24} style={{ transform: 'scale(1.2)', objectFit: 'contain' }} />
                        :
                        (shape === 'TrailerTop') ?
                          <img src={toAbsoluteUrl("/media/logos/trailertop.png")} alt='trailer-top-icon' width={24} height={24} style={{ transform: 'scale(1.2)', objectFit: 'contain' }} />
                          :
                          <ShapeIcon className="w-6 h-6" />
              }
            </button>
          );
        })}
      </div>
      <div className='separator my-3' style={{ border: '1px solid #ccc' }}></div>
      <h3 className='text-center fw-bold mb-5'>Settings</h3>
      <CirclePicker width="100%" colors={colors}
        circleSize={32}
        color={fillColor}
        onChangeComplete={(color) => {
          setFillColor(color.hex)
          setMainColor(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`)
        }}
      />
      {!['textbox', 'group', 'line']?.includes(selectedShape?.type) ?
        <div className="mt-5">
          <AlphaPicker width="95%" color={mainColor} onChange={(color) => {
            setFillColor(color.hex)
            setMainColor(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`)
          }}
          />
        </div>
        :
        selectedShape?.type === 'textbox' &&
        <div className="d-flex justify-content-between mt-5 gap-2">
          <select
            className="form-select form-select-solid fw-bolder"
            style={{ padding: '5px', backgroundPosition: 'right 5px center' }}
            value={fontFamily}
            onChange={(e) => setFontFamily(e.target.value)}
          >
            {fontFamilies.map((font) => (
              <option key={font} value={font}>{font}</option>
            ))}
          </select>
          <select
            className="form-select form-select-solid fw-bolder"
            style={{ padding: '5px 20px 5px 5px', width: 'max-content', backgroundPosition: 'right 5px center' }}
            value={fontSize}
            onChange={(e) => setFontSize(e.target.value)}
          >
            {fontSizes.map((size) => (
              <option key={size} value={size}>{size}px</option>
            ))}
          </select>
        </div>
      }
      <div className="d-flex justify-content-between gap-3 mt-5 position-relative">
        <button
          onClick={onBringToFront}
          className={`p-2 rounded shape-btn text-black bg-hover-light w-100`}
        >
          <BringToFront />
        </button>
        <button
          onClick={() => setIsBold(!isBold)}
          className={`p-2 rounded shape-btn text-black bg-hover-light w-100 ${isBold && 'active'}`}
        >
          <Bold />
        </button>
        <button
          onClick={() => setIsItalic(!isItalic)}
          className={`p-2 rounded shape-btn text-black bg-hover-light w-100 ${isItalic && 'active'}`}
        >
          <Italic />
        </button>
      </div>
      <div className='separator my-3' style={{ border: '1px solid #ccc' }}></div>
      <div className="d-flex justify-content-between gap-3">
        <button
          disabled={canvasLoading}
          onClick={() => onFlip('horizontal')}
          className="p-2 rounded shape-btn text-black bg-hover-light w-100"
        >
          <FlipHorizontal />
        </button>
        <button
          disabled={canvasLoading}
          onClick={() => onFlip('vertical')}
          className="p-2 rounded shape-btn text-black bg-hover-light w-100"
        >
          <FlipVertical />
        </button>
        <button
          disabled={canvasLoading}
          onClick={() => onRotate('left')}
          className="p-2 rounded shape-btn text-black bg-hover-light w-100"
        >
          <RotateCcw />
        </button>
        <button
          disabled={canvasLoading}
          onClick={() => onRotate('right')}
          className="p-2 rounded shape-btn text-black bg-hover-light w-100"
        >
          <RotateCw />
        </button>
      </div>
      <div className='separator my-3' style={{ border: '1px solid #ccc' }}></div>
      {imagePreview && <button className="btn btn-sm btn-main text-white fw-bold mt-auto"
        disabled={canvasLoading}
        onClick={() => onSaveImage()}
      >Save</button>
      }
    </div >
  );
};

export default Sidebar;