import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { KTCard, KTCardBody } from "../../../_metronic/helpers";
import axios from "axios";
import { useAuth } from "../auth";

const appType = {
    'google': "Google",
    'facebook': "Facebook",
    'google_play': "Google Play",
    'app_store': "App Store",
}
const Custom = () => {
    const { currentUser, haveAccess } = useAuth();
    const canAdd = (haveAccess(currentUser, 'custom_data_add') && currentUser?.access_level !== 3);
    const [ratings, setRatings] = useState({
        'google': '',
        'facebook': '',
        'google_play': '',
        'app_store': '',
    });
    const [reviews, setReviews] = useState({
        'google': '',
        'facebook': '',
        'google_play': '',
        'app_store': '',
    })

    const getCustomData = () => {
        axios.get(`${process.env.REACT_APP_API_V3_URL}/config`).then((res) => {
            if (res.data){
                setRatings(res.data.ratings)
                setReviews(res.data.reviews)
            }
        }).catch((err) => {
            if(err && err.response && err.response?.data && err.response?.data?.message){
              toast.error(err.response.data.message);
            }else{
              toast.error("Technical Error, Please Try Again");
            }
        })
    }

    useEffect(() => {
        if (haveAccess(currentUser, 'custom_data_view')) {
            getCustomData();
        }
    }, [])

    const isDisabled = () => {
        return Object.values(ratings).some(v => v === '')
            || Object.values(reviews).some(v => v === '');
    }

const resetData = () => {
    setRatings({
        'google': '',
        'facebook': '',
        'google_play': '',
        'app_store': '',
    });
    setReviews({
        'google': '',
        'facebook': '',
        'google_play': '',
        'app_store': '',
    })
}


const updateData = () => {
    axios.post(`${process.env.REACT_APP_API_V3_URL}/config`, {
        data: {
            ratings: ratings,
            reviews: reviews
        }
    }).then((res) => {
        toast.success("Data have been updated");
        getCustomData();
    }).catch((err) => {
            if(err && err.response && err.response?.data && err.response?.data?.message){
              toast.error(err.response.data.message);
            }else{
              toast.error("Technical Error, Please Try Again");
            }
        })
}

    return (
        haveAccess(currentUser, 'custom_data_view') ?
            <KTCard>
            <KTCardBody>
                <div className="kt_body">
                    <h1 className="mb-10 text-center">Total Reviews & Average Ratings</h1>
                    <div className="row g-5">
                        <div className="col-6">
                            <h3 className="mb-7">Average Ratings</h3>
                            {
                                Object.keys(ratings) && Object.keys(ratings).length > 0 &&
                                Object.keys(ratings).map((key) => (
                                    <div className="input-search mb-5" key={key}>
                                        <label className="form-label fs-6 fw-bold">{appType[key]} Ratings {key === 'facebook' && '(In Percentage)'}</label>
                                        <input
                                            type="text"
                                            data-kt-user-table-filter="search"
                                            className="form-control form-control-solid w-100 "
                                            placeholder={`Enter ${appType[key]} Ratings ${key === 'facebook' ? '(In Percentage)' : '(1-5)'}`}
                                            disabled={!canAdd}
                                            value={ratings[key]}
                                            onChange={(e) => {
                                                if (key === 'facebook') {
                                                    const value = e.target.value.replace(/[^0-9]/g, "");
                                                    if (value > 100) {
                                                        toast.error("Percentage cannot be greater than 100");
                                                    } else {
                                                        setRatings({ ...ratings, [key]: value });
                                                    }
                                                }else{
                                                    const newValue = e.target.value
                                                    const regex = /^(?:[1-4](?:\.\d{0,1})?|5(?:\.0)?)$/;
                                                    const isValidValue = regex.test(newValue)
                                                    if (isValidValue || e.target.value === '') {
                                                        setRatings({ ...ratings, [key]: newValue });
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                        <div className="col-6">
                            <h3 className="mb-7">Total Reviews</h3>
                            {
                                Object.keys(reviews) && Object.keys(reviews).length > 0 &&
                                Object.keys(reviews).map((key) => (
                                    <div className="input-search mb-5" key={key}>
                                        <label className="form-label fs-6 fw-bold">{appType[key]} Reviews</label>
                                        <input
                                            type="text"
                                            data-kt-user-table-filter="search"
                                            className="form-control form-control-solid w-100 "
                                            placeholder={`Enter ${appType[key]} Reviews Count`}
                                            value={reviews[key]}
                                            disabled={!canAdd}
                                            onChange={(e) => {
                                                const value = e.target.value.replace(/[^0-9]/g, "");
                                                setReviews({ ...reviews, [key]: value });
                                            }}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
               {canAdd && <div className="text-center pt-5">
                    <button
                        type="reset"
                        onClick={resetData}
                        className="btn btn-light me-3"
                        data-kt-users-modal-action="cancel"
                    >
                        Reset
                    </button>
                    <button
                        type="submit"
                        className="btn"
                        style={{ backgroundColor: "#ffa459", color: "#fff" }}
                        data-kt-users-modal-action="submit"
                        disabled={isDisabled()}
                        onClick={updateData}
                    >
                        <span className="indicator-label">Update</span>
                    </button>
                </div>
                }
            </KTCardBody>
        </KTCard>
        :
        <div className='d-flex text-center w-100 align-content-center justify-content-center' style={{ height: '70vh', alignItems: 'center', fontSize: '42px', color: '#ffa049', background: 'rgba(0,0,0,0.1)' }}>
                You do not have permission to view this page
        </div>
    )
}

export default Custom