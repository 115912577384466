import { KTIcon } from "../../../../../_metronic/helpers";

const UserEditModalHeader = ({
  setSelectedListingId,
  setOpenTimelineModal,
  listing,
}) => {
  return (
    <div className="modal-header">
      {/* begin::Modal title */}
      <div>
      <h2 className="fw-bolder listing_title">{`View Logs for (${listing.title})`}</h2>
      </div>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className="btn btn-icon btn-sm btn-active-icon-primary"
        data-kt-users-modal-action="close"
        onClick={() => {
          setSelectedListingId()
          setOpenTimelineModal(false)
        }}
        style={{ cursor: "pointer" }}
      >
        <KTIcon iconName="cross" className="fs-1" />
      </div>
      {/* end::Close */}
    </div>
  );
};

export { UserEditModalHeader };
