import { useEffect, useRef, useState } from 'react';
import { fabric } from 'fabric';
import { useCanvas } from '../context/canvasContext';
import useDimensions from '../hooks/useDimesion';
import './style.css';
import { UsersListLoading } from '../../Listings/SingleListing/loading/UsersListLoading';
import { rgbaToHex, hexToRgba } from '../utils/index';

const Canvas = ({ mainBodyRef }) => {
  const { canvasRef, canvasLoading, setCanvasLoading, imagePreview, setImageHeight, setImageWidth, setSelectedShape, setFillColor, setMainColor, setFontSize, setFontFamily, setIsBold, setIsItalic, selectedImage } = useCanvas();
  const [zoom, setZoom] = useState(1);

  useEffect(() => {
    if (canvasRef && !canvasRef.current) {
      canvasRef.current = new fabric.Canvas('canvas')
      canvasRef.current.setWidth(mainBodyRef?.current?.offsetWidth);
      canvasRef.current.setHeight(mainBodyRef?.current?.offsetHeight);
      canvasRef.current.on('selection:created', handleSelection);
      canvasRef.current.on('selection:updated', handleSelection);
      canvasRef.current.on('object:selected', (e) => {
        var obj = e.target;
        obj.setCoords()
        obj.bringToFront()
      })
      canvasRef.current.on('selection:cleared', () => {
        setSelectedShape(null);
        canvasRef.current.getObjects().forEach(obj => {
          if (obj?.controls?.deleteControl) {
            delete obj.controls.deleteControl;
          }
          if (obj?.controls?.cloneControl) {
            delete obj.controls.cloneControl;
          }
        });
      });
      canvasRef.current.on('object:modified', (e => {
        var obj = e.target;
        obj.setCoords()
      }))
    }
  }, []);


  const onRotate = () => {
    if (canvasRef && canvasRef.current) {
      const canvas = canvasRef.current;
      const backgroundImage = canvas.backgroundImage;

      if (backgroundImage) {
        const fixedWidth = canvas.getWidth();
        const fixedHeight = canvas.getHeight();

        let currentAngle = backgroundImage.angle || 0;

        backgroundImage.set({
          angle: currentAngle,
          originX: 'center',
          originY: 'center',
          left: fixedWidth / 2,
          top: fixedHeight / 2
        });

        const angleInRadians = fabric.util.degreesToRadians(currentAngle);
        const origWidth = backgroundImage.width;
        const origHeight = backgroundImage.height;
        const rotatedWidth = Math.abs(origWidth * Math.cos(angleInRadians)) +
          Math.abs(origHeight * Math.sin(angleInRadians));
        const rotatedHeight = Math.abs(origWidth * Math.sin(angleInRadians)) +
          Math.abs(origHeight * Math.cos(angleInRadians));
        const scaleX = fixedWidth / rotatedWidth;
        const scaleY = fixedHeight / rotatedHeight;
        const scale = Math.min(scaleX, scaleY);

        backgroundImage.set({
          scaleX: [0, 180, 360].includes(currentAngle) ? scaleX : scale,
          scaleY: [0, 180, 360].includes(currentAngle) ? scaleY : scale,
        });
        canvas.renderAll();
      }
    }
  };
  const onLoad = () => {
    setCanvasLoading(true)
    canvasRef.current.clear()
    fetch(selectedImage?.metadata)
      .then(response => response.json())
      .then(data => {
        canvasRef.current.loadFromJSON(data, () => {
          canvasRef.current.backgroundImage.set({
            top: 0,
            left: 0,
            originX: 'left',
            originY: 'top',
            scaleX: canvasRef.current.width / data.backgroundImage?.width,
            scaleY: canvasRef.current.height / data.backgroundImage?.height,
          })
          onRotate()
          setCanvasLoading(false)
        })
      })
      .catch(err => {
        console.error("Error loading canvas JSON:", err);
      });

  }

  useEffect(() => {
    if (canvasRef?.current && imagePreview) {
      fabric.Image.fromURL(imagePreview,
        () => {
          var image = new Image();
          image.src = imagePreview;
          image.onload = function () {
            setCanvasLoading(false)
            setImageHeight(image.height);
            setImageWidth(image.width);
            const containerWidth = mainBodyRef?.current?.offsetWidth * 0.9;
            const containerHeight = 550;
            const imageAspectRatio = image.width / image.height;
            const canvasWidth = image.width > containerWidth ? containerWidth : image.width;
            const canvasHeight = image.height > containerHeight ? containerHeight : image.width / imageAspectRatio;
            canvasRef.current.setWidth(canvasWidth);
            canvasRef.current.setHeight(canvasHeight);
            canvasRef.current.renderAll();
            if (selectedImage.metadata) {
              onLoad()
            } else {
              canvasRef.current.setBackgroundImage(imagePreview, canvasRef.current.renderAll.bind(canvasRef.current), {
                top: 0,
                left: 0,
                originX: 'left',
                originY: 'top',
                scaleX: canvasWidth / image.width,
                scaleY: canvasHeight / image.height,
                crossOrigin: 'anonymous'
              });
            }
          };
        });
    }
  }, [imagePreview, selectedImage]);

  const handleSelection = (e) => {
    const groups = e.selected && e.selected?.length > 1
    if (groups) {
      setSelectedShape(null)
    } else {
      const selected = e.selected[0];
      setSelectedShape(selected);
      if (selected.type !== 'group' || selected.type !== 'textbox') {
        if (selected.fill?.includes('#')) {
          setFillColor(selected.fill);
          setMainColor(hexToRgba(selected.fill, 1));
        } else {
          setFillColor(rgbaToHex(selected.fill));
          setMainColor(selected.fill);
        }
      }
      if (selected.type === 'line') {
        setFillColor(selected.stroke);
        setMainColor(hexToRgba(selected.stroke, 1));
      }
      if (selected.type === 'group') {
        if (selected.getObjects()[0]?.fill?.includes('#')) {
          setFillColor(selected.getObjects()[0]?.fill);
          setMainColor(hexToRgba(selected.getObjects()[0]?.fill, 1));
        } else {
          setFillColor(rgbaToHex(selected.getObjects()[0]?.fill));
          setMainColor(selected.getObjects()[0]?.fill);
        }
      }

      if (selected.type === 'textbox') {
        setFontSize(selected.fontSize);
        setFontFamily(selected.fontFamily);
        setIsBold(selected.fontWeight === 'bold');
        setIsItalic(selected.fontStyle === 'italic');
      }
    }
  };

  const handleZoom = (e) => {
    e.preventDefault(); // Prevent default scrolling
    const zoomFactor = 0.1;
    // Fabric.js wheel delta is opposite of native wheel delta
    let newZoom = zoom + (e.e.deltaY > 0 ? -zoomFactor : zoomFactor);

    newZoom = Math.min(Math.max(0.5, newZoom), 3); // Clamp zoom between 0.1 and 3

    setZoom(newZoom);

    if (canvasRef.current) {
      const pointer = canvasRef.current.getPointer(e.e);
      canvasRef.current.zoomToPoint(
        new fabric.Point(pointer.x, pointer.y),
        newZoom
      );
      canvasRef.current.renderAll();
    }
  };

  useEffect(() => {
    if (canvasRef.current) {

      fabric.Object.prototype.set({
        cornerColor: '#0066cc',
        cornerStrokeColor: '#fff',
        cornerSize: 10,
        cornerStyle: 'square',
        transparentCorners: false,
        borderColor: '#fff',
        strokeWidth: 2
      });

      canvasRef.current.renderAll();
    }
  }, [canvasRef.current]);

  return (
    <>
      <div ref={mainBodyRef} className='wrapper'>
        {canvasLoading && <UsersListLoading onTop={true} />}
        <canvas id="canvas" className={'canvas'} />
        {selectedImage?.reject_reason && <span className="bg-danger text-white fw-bold p-2 reject-reason">
          Previous changes was rejected: {selectedImage?.reject_reason}
        </span>
        }
      </div>
    </>
  );
}


export default Canvas