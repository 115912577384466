import { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import heic2any from 'heic2any';
import { toast } from 'react-toastify';

const PhotoUploader = ({ getAllImages, listing }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [uploading, setUploading] = useState(false);
  const [currentUploadIndex, setCurrentUploadIndex] = useState(0);

  const colorPercentChange = (percent) => {
    switch (true) {
      case percent <= 0:
        return 'bg-light';
      case percent < 10:
        return 'bg-danger text-white';
      case percent < 40:
        return 'bg-primary text-white';
      case percent < 90:
        return 'bg-main text-white';
      case percent >= 90:
        return 'bg-success text-white';
      default:
        return 'bg-light';
    }
  };

  const convertHeicToPreview = async (file) => {
    try {
      const convertedBlob = await heic2any({ blob: file, toType: 'image/jpeg' });
      const previewUrl = URL.createObjectURL(convertedBlob);
      return previewUrl;
    } catch (error) {
      console.error('Error converting HEIC file:', error);
      return null;
    }
  };


  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('listing_id', listing?.listing_id);
    formData.append('image', file.file);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        if (progressEvent.lengthComputable) {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          setUploadProgress((prev) => ({
            ...prev,
            [file.id]: progress,
          }));
        }
      },
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_V3_URL}/listing/images`,
        formData,
        config
      );
      
      if (response.status === 200) {
        setUploadProgress((prev) => ({
          ...prev,
          [file.id]: 100,
        }));
        setSelectedFiles((prev) => prev.filter((f) => f.id !== file.id));
        getAllImages();
        setCurrentUploadIndex((prev) => prev - 1);
      }
    } catch (error) {
      handleUploadError(file);
    }
  };

 
  const handleUploadError = (file) => {
    setUploadProgress((prev) => ({
      ...prev,
      [file.id]: 0,
    }));

    setSelectedFiles((prev) => prev.filter((f) => f.id !== file.id));

    setCurrentUploadIndex((prev) => prev - 1);

    getAllImages();
  };

  const processNextUpload = async () => {
    if (!selectedFiles.length) {
      setUploading(false); 
      setCurrentUploadIndex(0);
      return;
    }

    const currentFile = selectedFiles[currentUploadIndex - 1];

    if (!currentFile) {
      return;
    }

      await uploadFile(currentFile);
  };

  useEffect(() => {
    if (uploading && selectedFiles?.length) {
      processNextUpload();
    } else{
      setUploading(false);
    }
  }, [currentUploadIndex, uploading, selectedFiles]);

  const handleFileSelect = (event) => {
    event.preventDefault();
    const files = Array.from(event.target.files);

    if(files.length > 50) {
      toast.error('You can only upload 50 images at a time.');
      return;
    }
     
    const newFiles = files
    .map(async (file) => {
      if (file.type === 'image/heic' || file.name.toLowerCase().endsWith(".heic")) {
        const previewUrl = await convertHeicToPreview(file);
        return {
          file,
          id: Math.random().toString(36).substr(2, 9),
          preview: previewUrl,
          isHeic: true,
        };
      } else {
        return {
          file,
          id: Math.random().toString(36).substr(2, 9),
          preview: URL.createObjectURL(file),
          isHeic: false,
        };
      }
    });
  
    Promise.all(newFiles).then((resolvedFiles) => {
      setSelectedFiles((prev) => [...prev, ...resolvedFiles]);
      setCurrentUploadIndex(resolvedFiles.length);
      if (!uploading) {
        setUploading(true);
      }
    });
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);

    if(files.length > 50) {
      toast.error('You can only upload 50 images at a time.');
      return;
    }
  
    const newFiles = files
    .map(async (file) => {
      if (file.type === 'image/heic') {
        const previewUrl = await convertHeicToPreview(file);
        return {
          file,
          id: Math.random().toString(36).substr(2, 9),
          preview: previewUrl,
          isHeic: true,
        };
      } else {
        return {
          file,
          id: Math.random().toString(36).substr(2, 9),
          preview: URL.createObjectURL(file),
          isHeic: false,
        };
      }
    });
  
    Promise.all(newFiles).then((resolvedFiles) => {
      setSelectedFiles((prev) => [...prev, ...resolvedFiles]);
      setCurrentUploadIndex(resolvedFiles.length);
      if (!uploading) {
        setUploading(true);
      }
    });
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <div className='mb-2' style={{ width: '90%' }}>
        <label style={{ display: 'block', width: '100%' }}>
          <div
            style={{
              border: '2px dashed #D1D5DB',
              borderRadius: '0.375rem',
              padding: '3rem 2rem',
              textAlign: 'center',
              cursor: 'pointer',
              transition: 'border-color 0.3s',
            }}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <i className='fas fa-upload fw-bold text-muted mb-2 fs-1' />
            <p className='fs-4 fw-semibold text-muted'>
              Drop Images here or click to upload (Max: 50 Images)
            </p>
          </div>
          <input
            type="file"
            multiple
            accept="'image/jpg, image/jpeg, image/png, image/heic, image/webp"
            style={{ display: 'none' }}
            onChange={handleFileSelect}
            disabled={uploading}
          />
        </label>
      </div>

      {selectedFiles && selectedFiles.length > 0 && (
        <div>
          <h2 className='mb-2'>Image Uploading...</h2>
          <div className="d-flex flex-wrap" style={{ justifyContent: 'space-between', rowGap: '10px' }}>
            {selectedFiles.map((file) => (
              <div key={file.id} className='d-flex gap-3 align-items-center w-50'>
                {file.preview ? (
                  <img
                    src={file.preview}
                    alt="Preview"
                    style={{
                      width: '50px',
                      height: '50px',
                      objectFit: 'cover',
                      borderRadius: '5px',
                    }}
                  />
                ) : (
                  <div style={{ width: '50px', height: '50px', background: '#f3f3f3', borderRadius: '5px' }} />
                )}
                <div className="progress" style={{ width: 'calc(90% - 60px)', height: '25px' }}>
                  {uploadProgress[file.id] >= 0 && (
                    <div
                      className={`progress-bar rounded fs-7 fw-bold ${colorPercentChange(uploadProgress[file.id] || 0)} position-relative`}
                      role='progressbar'
                      style={{ width: `${uploadProgress[file.id] || 0}%`, border:'1px solid #000000' }}
                      aria-valuenow={uploadProgress[file.id]}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      {uploadProgress[file.id] > 30 ? 'Uploading' :''} {uploadProgress[file.id] || 0}%
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default PhotoUploader;