import { forwardRef } from 'react';
import ImageViewer from "awesome-image-viewer";
import { useAuth } from '../../../auth';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useCanvas } from '../../../ImageEditor/context/canvasContext';
import { Star } from "lucide-react"

const ImageCard = forwardRef(({ id, data, withOpacity, isLoading, setLoading, getListing, getAllImages, setReasonModal, listing, showImageStatus, isDragging, setOpenPhotoEditModal, style, ...props }, ref) => {
  const { currentUser, haveAccess } = useAuth();
  const { setImagePreview, setSelectedImage, setCanvasLoading } = useCanvas()

  const Styles = {
    position: 'relative',
    opacity: withOpacity ? '0.5' : '1',
    transformOrigin: '50% 50%',
    height: '200px',
    width: '200px',
    cursor: isDragging ? 'grabbing' : 'grab',
    backgroundColor: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: isDragging ? 'rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px' : 'rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px',
    transform: isDragging ? 'scale(1.05)' : 'scale(1)',
    ...style,
  };

  function DownloadImage(url) {
    setLoading(true)
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const blobURL = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = blobURL;
        a.download = url.split("/").pop();
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(blobURL);
        setLoading(false)
      })
      .catch(error => {
        toast.error('Error downloading image:')
        setLoading(false)
      });
  }

  const makeProfilePicture = (id) => {
    setLoading(true)
    axios
      .put(`${process.env.REACT_APP_API_URL}/listing/${listing?.listing_id}`, {
        avatar: id,
      })
      .then((res) => {
        setLoading(false)
        getListing();
        toast.success("Profile Picture Updated Successfully");
      })
      .catch((err) => {
        setLoading(false)
        toast.error("Error Changing Default Picture");
      });
  };

  const DeleteImage = (id, file) => {
    setLoading(true)
    if (file === listing?.avatar) {
      toast.error("Profile Picture Cannot be Deleted");
    } else {
      axios.delete(`${process.env.REACT_APP_API_V3_URL}/listing/images?asset_id=${id}`).then(res => {
        toast.success("Image Deleted Successfully");
        getListing()
        getAllImages()
        setLoading(false)
      }).catch(err => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast.error(err.response.data.message)
          setLoading(false)
        }
      })
    }
  }
  const ApproveRequestImage = (id) => {
    setLoading(true)
    axios.patch(`${process.env.REACT_APP_API_V3_URL}/listing/images/request?asset_id=${id}`).then(res => {
      toast.success("Approval Request Sent");
      getAllImages()
      setLoading(false)
    }).catch(err => {
      if (err && err.response && err.response?.data && err.response?.data?.message) {
        toast.error(err.response.data.message)
        setLoading(false)
      }
    })
  }


  return (
    <div ref={ref} style={Styles} {...props} className='rounded-sm position-relative img-container'>
      <div className="d-flex flex-column download-img-btn-wrapper">
      {(haveAccess(currentUser, 'listing_photo_approve')) && <button
        className="download-img-btn"
        disabled={isLoading}
        onClick={() => DownloadImage(`${process.env.REACT_APP_IMAGE_URL.replace('1:', '1x:')}og:og:${data?.pending_file ? data?.pending_file.slice(1) : data?.latest_file.slice(1)}`)}
      >
        <i className="fas fa-download" />
      </button>
      }
       {(haveAccess(currentUser, 'listing_photo_edit')) && <button
        className="download-img-btn edit-img-btn"
        onClick={() => {
          setCanvasLoading(true)
          setImagePreview(`${process.env.REACT_APP_IMAGE_URL.replace('1:', '1x:')}og:og:${data?.pending_file ? data?.pending_file.slice(1) : data?.latest_file.slice(1)}`)
          setSelectedImage(data)
          setOpenPhotoEditModal(true)
        }}
      >
        <i className="fas fa-pencil" />
      </button>
      }
      </div>
      {(haveAccess(currentUser, 'listing_photo_request')) && (data.status == 0 || data.status == 2) && <button
        className="approve-img-btn"
        disabled={isLoading}
        onClick={() => ApproveRequestImage(data.asset_id)}
      >
        Send For Approval
      </button>
      }
      {(haveAccess(currentUser, 'listing_photo_delete')) && [0, 1, 2].includes(data.status) && (data.latest_file !== listing?.avatar) && <button
        className="remove-img-btn"
        disabled={isLoading}
        onClick={() => DeleteImage(data.asset_id, data.latest_file)}
      > Delete
      </button>
      }
      {data.status == 1 && data.latest_file !== listing?.avatar && haveAccess(currentUser, 'listing_edit') && <button
        className="approve-img-btn"
        disabled={isLoading}
        onClick={() => makeProfilePicture(data.asset_id)}
      >
        <i
          className="fas fa-star text-white"
        ></i> Make Profile
      </button>
      }
      {(data?.pending_file && data?.latest_file === listing?.avatar) && <button
        className="approve-img-btn p-1"
        disabled={true}
      >
        <i
          className="fas fa-star text-white"
        ></i>
      </button>
      }
      {[1, -1, 2, 3].includes(data.status) && <span className={`status badge badge-${data?.pending_file && data?.status === 1 ? 'primary' : showImageStatus(data.status).color} fs-6`}>{data?.pending_file && data?.status === 1 ? 'Pending Reapproval' : data.latest_file === listing.avatar ? 'Profile Picture' : showImageStatus(data.status).text}</span>}
      <img
        src={process.env.REACT_APP_IMAGE_URL + '300:200:' + (data?.pending_file || data?.latest_file).slice(1)}
        style={{
          width: "100%",
          height: "100%",
          borderRadius: '6px',
          border: data?.latest_file === listing?.avatar ? '3px solid #ffa459' : 'none',
          cursor: 'inherit'
        }}
        alt="images"
        onClick={() =>
          new ImageViewer({
            images: [
              {
                mainUrl: process.env.REACT_APP_IMAGE_URL + "1920:720:" + (data?.pending_file || data?.latest_file).slice(1)
              }
            ],
            isZoomable: false,
            closeOnEsc: true
          })
        }
      />
      {data.status === 2 && data.reject_reason && <button
        className="reject-reason-btn"
        disabled={isLoading}
        onClick={() => setReasonModal({
          status: true,
          rejectReason: data.reject_reason
        })}
      >
        View Reject Reason
      </button>
      }
    </div>
  )
});

export default ImageCard;


