import css from "./breakdown.module.scss";
import { formatPrice, getDiscountDetails, getEnd } from "../../../../../_metronic/helpers/utils";
import dayjs from "dayjs";
import { useEffect, useState } from 'react'

export const Breakdown = ({
  type,
  hours,
  days,
  start,
  end,
  price,
  vehiclesAmount,
  weeks,
  discount,
  promoCode,
  bookingDetails,
  tax
}) => {
  const [totalPrice, setTotalPrice] = useState(0);
  const getStart = () => {
    return {
      day: dayjs(start).format("dddd"),
      date: dayjs(start).format("MMM DD")
    };
  };

  const getEndDate = () => {
    return {
      day: dayjs(end).format("dddd"),
      date: dayjs(end).format("MMM DD")
    };
  };

  const getAmount = () => {
    if (type == 1) {
      return {
        amount: hours,
        type: +hours === 1 ? "hour" : "hours"
      };
    } else if (type == 2) {
      return {
        amount: days,
        type: +days == 1 ? "day" : "days"
      };
    } else if (type == 3) {
      return {
        amount: weeks,
        type: "week"
      };
    } else if (type == 4) {
      return {
        amount: 1,
        type: "month"
      };
    }
  };


  const typesOfVechiles = {
    1: 'Truck',
    2: 'Trailer',
    3: 'Truck & Trailer',
  }



  useEffect(() => {
    let totalPrice = 0
    bookingDetails?.vehicles.map((vehicle) => {
      const vehicleType = vehicle?.vehicle_type
      const pricey = bookingDetails.prices.find((item) => item.vehicle_type === vehicleType ? item.vehicle_type === vehicleType : item.vehicle_type == 0)?.price
        totalPrice += +pricey
    })

    setTotalPrice(totalPrice)
},[bookingDetails])



  const getVehicleNumber = (allVehicles = [], selectedVehicle) => {
    const vehicleNumber = allVehicles.find((el) => el.vehicle_id == selectedVehicle.vehicle_id)?.vehicle_type
    if (vehicleNumber) {
      switch (vehicleNumber) {
        case 1:
          return allVehicles.find((el) => el.vehicle_id == selectedVehicle.vehicle_id)?.truck_number
        case 2:
          return allVehicles.find((el) => el.vehicle_id == selectedVehicle.vehicle_id)?.trailer_number
        case 3:
          return `${allVehicles.find((el) => el.vehicle_id == selectedVehicle.vehicle_id)?.truck_number}/${allVehicles.find((el) => el.vehicle_id == selectedVehicle.vehicle_id)?.trailer_number}`
        default:
          return allVehicles.find((el) => el.vehicle_id == selectedVehicle.vehicle_id)?.truck_number
      }
    }
  }



  return (
    <div className={css.breakdown}>
      <div className={css.dates}>
        <div>
          <div className={css.label}>Booking start</div>
          <div className={css.title}>{getStart().day}</div>
          <div className={css.title}>{getStart().date}</div>
        </div>
        <div className={css.right}>
          <div className={css.label}>Booking end</div>
          <div className={css.title}>{end ? getEndDate().day : getEnd(type, start, hours, days, weeks).day}</div>
          <div className={css.title}>{end ? getEndDate().date : getEnd(type, start, hours, days, weeks).date}</div>
        </div>
      </div>
      {
        bookingDetails?.vehicles && bookingDetails?.vehicles.map((item) => {
          const vehicleType = item?.vehicle_type
          const pricey = bookingDetails.prices.find((item) => item.vehicle_type === vehicleType ? item.vehicle_type === vehicleType : item.vehicle_type == 0)?.price
          return (
            <div className={css.calculation}>
              <div>
                {formatPrice(pricey)} x {getAmount().amount} {getAmount().type} x {vehiclesAmount} {typesOfVechiles[vehicleType]} ({getVehicleNumber(bookingDetails.vehicles, item)})
              </div>
              <div>{formatPrice(pricey * getAmount().amount * vehiclesAmount)}</div>
            </div>
          )
        })
      }
      {/* <div className={css.calculation}>
        <div>
          {formatPrice(price)} x {getAmount().amount} {getAmount().type} x {vehiclesAmount} {vehiclesAmount === 1 ? "vehicle" : "vehicles"}
        </div>
        <div>{formatPrice(price * getAmount().amount * vehiclesAmount)}</div>
      </div> */}
      {promoCode && discount?.discounted_amount && <div className={css.promo}>
        <div>
          Discount
          ({promoCode})-({getDiscountDetails(discount, type)})
          {discount?.discounted_amount == 50000 ? "(Up to $500)" : ""}
        </div>
        <div>${(discount?.discounted_amount / 100)?.toFixed(2)}</div>
      </div>
      }
      {(tax && tax > 0) ? <div className={css.promo}>
        <div>State Sales Tax</div>
        <div className={css.title}>+${(tax/100)?.toFixed(2)}</div>
        </div>
        : null
      }
      <div className={css.total}>
        <div>Total price</div>
        <div className={css.title}>${((((totalPrice * getAmount().amount * 1)/100) + tax/100) - (discount?.discounted_amount ? (discount?.discounted_amount / 100).toFixed(2) : 0))?.toFixed(2)}</div>
      </div>
    </div>
  );
};
