import { useEffect, useState } from 'react'
import axios from 'axios'
import { StatisticsWidget5 } from '../../../_metronic/partials/widgets'
import { toast } from "react-toastify";
import { formatPrice } from '../../../_metronic/helpers/utils';
import { useAuth } from '../auth';
import { WalletInfoTable }  from './table/WalletInfoTable';
import { KTCard, useDebounce } from '../../../_metronic/helpers'


const Index = () => {
    const { haveAccess, currentUser } = useAuth()
    const [data, setData] = useState()
    const [walletData, setWalletData] = useState({})
    const [isLoading, setLoading] = useState(false);
    const [pageSize, setPageSize] = useState(10)
    const [page, setPage] = useState(1)
    const [searchType, setSearchType] = useState('member_id')
    const [search, setSearch] = useState('')

    const getWalletsStats = () => {
        axios
            .get(`${process.env.REACT_APP_API_V2_URL}/reports/wallet?page_size=${pageSize}&page=${page}`)
            .then((response) => {
                setData(response.data)
            })
            .catch((err) => {
                if (err && err.response && err.response?.data && err.response?.data?.message) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error("Technical Error, Please Try Again");
                }
            });
    }

    useEffect(() => {
        getWalletsStats()
    }, [])

    const getWalletTable = () => {
    setLoading(true)
    axios.get(`${process.env.REACT_APP_API_V3_URL}/wallets?page_size=${pageSize}&page=${page}&${searchType}=${search}`).then((response) => {
        setWalletData(response.data)
        setLoading(false)
    }).catch((err) => {
        setLoading(false)
        if (err && err.response && err.response?.data && err.response?.data?.message) {
            toast.error(err.response.data.message);
        } else {
            toast.error("Technical Error, Please Try Again");
        }
    })
}

const debouncedSearchTerm = useDebounce(search, 700);

useEffect(() => {
    getWalletTable()
}, [page, pageSize, debouncedSearchTerm])

    return (
        <>
            {haveAccess(currentUser, 'view_cash_stats') ?
                <div className='row g-5 g-xl-2 mb-5'>
                    <div className='col-xl-4'>
                        <StatisticsWidget5
                            className='card-xl-stretch mb-xl-8'
                            svgIcon='wallet'
                            color='main'
                            iconColor='white'
                            title={data?.total_cash ? formatPrice(data?.total_cash) : '$0'}
                            titleColor='white'
                            description='Total Cash Deposited All Time'
                            descriptionColor='white'
                        />
                    </div>

                    <div className='col-xl-4'>
                        <StatisticsWidget5
                            className='card-xl-stretch mb-xl-8'
                            svgIcon='wallet'
                            color='dark'
                            iconColor='white'
                            title={data?.cash_owed ? formatPrice(data?.cash_owed) : '$0'}
                            titleColor='white'
                            description='Total Balances In Wallet Now'
                            descriptionColor='white'
                        />
                    </div>
                    <div className='col-xl-4'>
                        <StatisticsWidget5
                            className='card-xl-stretch mb-xl-8'
                            svgIcon='dollar'
                            color='danger'
                            iconColor='white'
                            title={data?.cash_consumed ? formatPrice(data?.cash_consumed) : '$0'}
                            titleColor='white'
                            description='Cash Consumed'
                            descriptionColor='white'
                        />
                    </div>
                    <div className='col-xl-4'>
                        <StatisticsWidget5
                            className='card-xl-stretch mb-xl-8'
                            svgIcon='dollar'
                            color='danger'
                            iconColor='white'
                            title={data?.cash_consumed_month ? formatPrice(data?.cash_consumed_month) : '$0'}
                            titleColor='white'
                            description='Cash Consumed This Month'
                            descriptionColor='white'
                        />
                    </div>
                    <div className='col-xl-4'>
                        <StatisticsWidget5
                            className='card-xl-stretch mb-xl-8'
                            svgIcon='gift'
                            color='info'
                            iconColor='white'
                            title={data?.total_rewards ? formatPrice(data?.total_rewards) : '$0'}
                            titleColor='white'
                            description='Cumulative Reward'
                            descriptionColor='white'
                        />
                    </div>
                    <div className='col-xl-4'>
                        <StatisticsWidget5
                            className='card-xl-stretch mb-5 mb-xl-8'
                            svgIcon='profile-user'
                            color='primary'
                            iconColor='white'
                            title={data?.cash_users ? data?.cash_users : 0}
                            titleColor='white'
                            description='Active Wallet Users'
                            descriptionColor='white'
                        />
                    </div>
                </div>
                :
                <div className='d-flex text-center w-100 align-content-center justify-content-center' style={{ height: '70vh', alignItems: 'center', fontSize: '42px', color: '#ffa049', background: 'rgba(0,0,0,0.1)' }}>
                    You do not have permission to view widgets
                </div>
            }
            {(haveAccess(currentUser, 'wallets_list')) ?
            <KTCard>
                <WalletInfoTable
                    walletData={walletData}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    page={page}
                    setPage={setPage}
                    search={search}
                    setSearch={setSearch}
                    searchType={searchType}
                    setSearchType={setSearchType}
                    isLoading={isLoading}
                />
            </KTCard>
            :
            <div className='d-flex text-center w-100 align-content-center justify-content-center' style={{ height: '70vh', alignItems: 'center', fontSize: '42px', color: '#ffa049', background: 'rgba(0,0,0,0.1)' }}>
                You do not have permission to view wallet table
            </div>
        }
        </>
    )
}

export default Index