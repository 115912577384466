import { TimelineModalForm } from "./TimelineModalForm"

const TimelineModalFormWrapper = ({
  openTimelineModal,
  setOpenTimelineModal,
  user,
  getUser
}) => {

    return (
      <TimelineModalForm
        user={user}
        getUser={getUser}
        openTimelineModal={openTimelineModal}
        setOpenTimelineModal={setOpenTimelineModal}
      />
    );
  }


export { TimelineModalFormWrapper };
