import {
    useState,
    createContext,
    useContext,
    useRef,
} from 'react'

const CanvasContext = createContext()

const useCanvas = () => {
    return useContext(CanvasContext)
}

const CanvasProvider = ({ children }) => {
    const canvasRef = useRef(null)
    const [selectedShape, setSelectedShape] = useState(null)
    const [imagePreview, setImagePreview] = useState(null)
    const [imageHeight, setImageHeight] = useState(0)
    const [imageWidth, setImageWidth] = useState(0)
    const [fillColor, setFillColor] = useState('#ffffff')
    const [mainColor, setMainColor] = useState('rgba(255, 255, 255, 1)')
    const [fontFamily, setFontFamily] = useState('Roboto Slab')
    const [fontSize, setFontSize] = useState(20)
    const [isBold, setIsBold] = useState(false)
    const [isItalic, setIsItalic] = useState(false)
    const [selectedImage, setSelectedImage] = useState(null)
    const [canvasLoading, setCanvasLoading] = useState(false)


    const getCanvasAtResoution = (newWidth, newHeight) => {
        if (canvasRef?.current) {
            let canvas = canvasRef.current
            if (canvas.width != newWidth) {
                var scaleMultiplier = newWidth / (canvas.width ?? 1)
                var objects = canvas.getObjects()
                for (var i in objects) {
                    objects[i].scaleX = (objects[i].scaleX ?? 1) * scaleMultiplier
                    objects[i].scaleY = (objects[i].scaleY ?? 1) * scaleMultiplier
                    objects[i].left = (objects[i].left ?? 1) * scaleMultiplier
                    objects[i].top = (objects[i].top ?? 1) * scaleMultiplier
                    objects[i].setCoords()
                }
                var obj = canvas.backgroundImage
                if (obj) {
                    (obj).scaleX = ((obj)?.scaleX ?? 1) * scaleMultiplier;
                    (obj).scaleY = ((obj)?.scaleY ?? 1) * scaleMultiplier
                }

                canvas.discardActiveObject()
                canvas.setWidth(newWidth)
                canvas.setHeight(newHeight)
                canvas.renderAll()
                canvas.calcOffset()
            }
        }
    }


    return (
        <CanvasContext.Provider value={{
            canvasRef,
            selectedShape,
            setSelectedShape,
            getCanvasAtResoution,
            imagePreview,
            setImagePreview,
            imageWidth,
            setImageWidth,
            imageHeight,
            setImageHeight,
            fillColor,
            setFillColor,
            mainColor,
            setMainColor,
            fontFamily,
            setFontFamily,
            fontSize,
            setFontSize,
            isBold,
            setIsBold,
            isItalic,
            setIsItalic,
            selectedImage,
            setSelectedImage,
            canvasLoading,
            setCanvasLoading
        }}>
            {children}
        </CanvasContext.Provider>
    )
}

export { CanvasProvider, useCanvas }
