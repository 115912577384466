import { KTIcon } from '../../../../_metronic/helpers'


const VehicleSwapModalHeader = ({ selectedVehicle, setSelectedVehicle, selectedBookingId, setEditVehicle, setShowModal }) => {


  return (
    <div className='modal-header' style={{ flexDirection: "column", paddingBottom: 0 }}>
      <div className='d-flex align-items-center justify-content-between w-100'>
        <h2 className='fw-bolder'>Select Vehicle to Change with {selectedVehicle?.vehicle_type !== 2 ? "Truck" : "Trailer"} #{selectedVehicle?.vehicle_type !== 2 ? selectedVehicle.truck_number : selectedVehicle.trailer_number}</h2>
        <div
          className='btn btn-icon btn-sm btn-active-icon-primary'
          data-kt-users-modal-action='close'
          onClick={() => {
            setSelectedVehicle(null)
          }}
          style={{ cursor: 'pointer' }}
        >
          <KTIcon iconName='cross' className='fs-1' />
        </div>
      </div>
      <div
        className="vehicle-card w-100 mt-2"
        style={{
          borderRadius: "10px",
          border: "2px solid #2ecc71",
          padding: "10px",
          background: "#f5f5f5",
          marginBottom: "10px",
          display: "flex",
          gap: "10px",
          flexWrap: "wrap",
          position: "relative",
          marginBottom: "15px"
        }}
      >
        <span
          style={{
            position: "absolute",
            background: "#2ecc71",
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            right: "-10px",
            top: "-10px",
            cursor: "pointer",
          }}
          onClick={() => {
            setShowModal(true)
            setEditVehicle(selectedVehicle)
          }}
        >
          <i
            className="fas fa-pencil-alt"
            style={{ color: "#fff" }}
          />
        </span>
        <div className="vehicle-label">
          <h6 style={{ color: "#a3a3a3" }}>
            VEHICLE ID
          </h6>
          <h5 style={{ color: "#4a4a4a" }}>
            {selectedVehicle?.vehicle_id || "-"}
          </h5>
        </div>
        <div className="vehicle-label">
          <h6 style={{ color: "#a3a3a3" }}>
            VEHICLE TYPE
          </h6>
          <h5 style={{ color: "#4a4a4a" }}>
            {+selectedVehicle?.vehicle_type === 1
              ? "TRUCK"
              : +selectedVehicle?.vehicle_type === 2
                ? "TRAILER"
                : "TRUCK + TRAILER"
            }
          </h5>
        </div>
        {+selectedVehicle.vehicle_type !== 2 && (
          <div className="vehicle-label">
            <h6 style={{ color: "#a3a3a3" }}>
              TRUCK COMPANY NAME
            </h6>
            <h5 style={{ color: "#4a4a4a" }}>
              {selectedVehicle?.name || "-"}
            </h5>
          </div>
        )}
        {+selectedVehicle.vehicle_type !== 2 && (
          <div className="vehicle-label">
            <h6 style={{ color: "#a3a3a3" }}>
              TRUCK #
            </h6>
            <h5 style={{ color: "#4a4a4a" }}>
              {selectedVehicle?.truck_number || "-"}
            </h5>
          </div>
        )}
        {+selectedVehicle.vehicle_type !== 2 && (
          <div className="vehicle-label">
            <h6 style={{ color: "#a3a3a3" }}>
              TRUCK MAKE
            </h6>
            <h5 style={{ color: "#4a4a4a" }}>
              {selectedVehicle?.make || "-"}
            </h5>
          </div>
        )}
        {+selectedVehicle.vehicle_type !== 2 && (
          <div className="vehicle-label">
            <h6
              style={{
                color: "#a3a3a3",
                textTransform: "uppercase"
              }}
            >
              TRUCK PLATE #
            </h6>
            <h5 style={{ color: "#4a4a4a" }}>
              {selectedVehicle?.plate_number || "-"}
            </h5>
          </div>
        )}
        {+selectedVehicle.vehicle_type !== 2 && (
          <div className="vehicle-label">
            <h6
              style={{
                color: "#a3a3a3",
                textTransform: "uppercase"
              }}
            >
             TRUCK VIN #
            </h6>
            <h5 style={{ color: "#4a4a4a" }}>
              {selectedVehicle?.vin_number || "-"}
            </h5>
          </div>
        )}
        {+selectedVehicle.vehicle_type !== 1 && (
          <div className="vehicle-label">
            <h6 style={{ color: "#a3a3a3" }}>
              TRAILER TYPE
            </h6>
            <h5 style={{ color: "#4a4a4a" }}>
              {selectedVehicle.vehicle_type !== 1
                ? selectedVehicle?.model || "-"
                : "Not Available"
              }
            </h5>
          </div>
        )}
        {+selectedVehicle.vehicle_type !== 1 && (
          <div className="vehicle-label">
            <h6 style={{ color: "#a3a3a3" }}>
              TRAILER #
            </h6>
            <h5
              style={{
                color: "#4a4a4a",
                textTransform: "uppercase"
              }}
            >
              {selectedVehicle?.trailer_number || "-"}
            </h5>
          </div>
        )}
        {+selectedVehicle.vehicle_type !== 1 && (
          <div className="vehicle-label">
            <h6 style={{ color: "#a3a3a3" }}>
              TRAILER COMPANY NAME
            </h6>
            <h5
              style={{
                color: "#4a4a4a",
                textTransform: "uppercase"
              }}
            >
              {selectedVehicle?.company_name || "-"}
            </h5>
          </div>
        )}
        {+selectedVehicle.vehicle_type !== 1 && (
          <div className="vehicle-label">
            <h6 style={{ color: "#a3a3a3" }}>
              TRAILER PLATE #
            </h6>
            <h5
              style={{
                color: "#4a4a4a",
                textTransform: "uppercase"
              }}
            >
              {selectedVehicle?.trailer_plate || "-"}
            </h5>
          </div>
        )}
        {+selectedVehicle.vehicle_type !== 1 && (
        <div className="vehicle-label">
            <h6 style={{ color: "#a3a3a3" }}>
              TRAILER VIN #
            </h6>
            <h5
              style={{
                color: "#4a4a4a",
                textTransform: "uppercase"
              }}
            >
              {selectedVehicle?.vin_number_2 || "-"}
            </h5>
          </div>
          )}
      </div>
    </div>
  )
}

export { VehicleSwapModalHeader }
